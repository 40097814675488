.input-group-text {
	padding: 0.7rem 1.2rem;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bs-body-color);
	text-align: center;
	white-space: nowrap;
	background-color: var(--bs-body-bg);
	border: var(--bs-border-width) solid var(--bs-border-color);
	border-radius: var(--bs-border-radius);
}

.input {
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 1rem;
	padding: 1rem 1.2rem;
	background-color: var(--bs-body-bg);
	border: 2px solid var(--bs-border-color);
	background-clip: padding-box;
	border-radius: var(--bs-border-radius);
	transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.input:focus-within {
	color: var(--bs-body-color);
	background-color: var(--bs-body-bg);
	border-color: var(--bs-secondary);
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(211, 108, 11, 0.25);
}

.input input,
.input textarea {
	width: 100%;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.5;
	color: var(--bs-body-color);
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	border: none;
	outline: none;
}

.input .icon {
	color: #a9a9a9;
}

.form-control:focus {
	background: transparent;
	border: hidden !important;
	outline: hidden !important;
	box-shadow: none !important;
}
/* === Disabled states === */

.disabled {
	pointer-events: none;
}

.form-control:disabled {
	background-color: var(--bs-secondary-bg);
	opacity: 1;
}

.input--disabled {
	background-color: #f2f2f2;
}

input:disabled {
	background: transparent;
}

.cl-disabled {
	color: #a9a9a9;
}

/* === Placeholder === */

.input input::placeholder,
.input textarea::placeholder {
	color: #a9a9a9;
}

.input input::-moz-placeholder,
.input textarea::-moz-placeholder {
	color: #a9a9a9;
}

.input input::-webkit-input-placeholder,
.input textarea::-webkit-input-placeholder {
	color: #a9a9a9;
}

.input input::-webkit-calendar-picker-indicator {
	background: none;
}

/* === Radios and Checkboxes === */

.form-check-input:checked {
	background-color: #ff7834;
	border-color: #ff7e3d;
}

.form-check-input:focus {
	border-color: #ff7e3d;
	outline: 0;
	box-shadow: 0 0 0 0.25rem rgba(253, 129, 13, 0.25);
}

/* === Selects ===*/

.select-list {
	display: flex;
	flex-direction: column;
	width: 100%;
	border-radius: 4px;
	padding: 0.5rem;
	position: absolute;
	z-index: 99;
	background-color: white;
	border: solid 2px #c5c5c5;
	max-height: 150px;
	overflow-y: auto;
	left: 0;
	margin-top: 0.5rem;
	animation: ease-in-out showSelectList 300ms;
}

.select-list__item {
	width: 100%;
	font-size: 1rem;
	padding: 0.75rem 1rem;
	transition: background-color ease-in-out 500ms;
	cursor: pointer;
	outline: none;
}

.select-list__item--active {
	background-color: #2564eb1c;
	border: solid 2px rgba(0, 68, 255, 0.5);
}

.select-list__item:hover {
	background-color: #2564eb1c;
}

.select-list__item:focus {
	background-color: #2564eb1c;
	border: solid 2px rgba(0, 68, 255, 0.5);
}

@keyframes showSelectList {
	from {
		transform: translateY(-20px);
	}

	to {
		transform: translateY(0);
	}
}
