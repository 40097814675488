
/* *,*/
*:before, 
*:after {
  margin: 0px;
  padding: 0px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
} 
body,
html {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 15px;
  color: #666666;

  height: 100%;
  background-color: #fff;
  -webkit-box-sizing: border-box;

  -moz-box-sizing: border-box;

  box-sizing: border-box;
} 

/* ----------------- Scrollbar ---------------- */
 *::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background-color: #f3f3f3;
  border-radius: 20px;
}

*::-webkit-scrollbar-thumb {
  background-color: #bbc3c5;
  border-radius: 20px;
}

/*---------------------------------------------*/
 button {
  outline: none;
  border: none;
  //background: transparent;
  cursor: pointer;
}

.card-image-height {
  height: 278px;
}

.custom-card {
  min-height: 200px; 
  min-width: 300px; 
  max-width: 350px; 
  height: 100%;     
}

.buttonprod{
  background-color: #D3D3D3;
  color: white;
}

.buttonprod:hover {
  background-color: #D3D3D3; 
  opacity: 1; 
  color: white;
 
}

.button-dark {
  background-color: #666666;
  border: none; 
  color: white; 
  transition: none; 
}

.button-dark:hover {
  background-color: #666666; 
  opacity: 1; 
  color: white;
 
}


button:focus {
  outline: none;
}

ul,
li {
/*   margin: 0px;
 */  list-style-type: none;
/*   padding: 0;
 */

} 

ul {
  padding-left: 0;
}
 
/*------------------------------------------------------------------
[ Bootstrap ]*/
 .container {
  max-width: 1380px;
}

@media (max-width: 1600px) {
  .container {
    max-width: 1200px;
  }
}

.avatar {
  --tblr-border-radius: 4px;
  --tblr-gray-100: #f6f8fb;
  --tblr-border-color-translucent: rgba(4, 32, 69, 0.14);
  --tblr-font-weight-medium: 500;
  --tblr-bg-surface-secondary: var(--tblr-gray-100);
  --tblr-avatar-size: 2.5rem;
  --tblr-avatar-bg: var(--tblr-bg-surface-secondary);
  --tblr-avatar-shadow: inset var(--tblr-border-color-translucent) 0 0 0 1px;
  position: relative;
  width: var(--tblr-avatar-size);
  height: var(--tblr-avatar-size);
  font-size: calc(var(--tblr-avatar-size) / 2.8571428572);
  font-weight: var(--tblr-font-weight-medium);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--tblr-muted);
  text-align: center;
  text-transform: uppercase;
  vertical-align: bottom;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background: var(--tblr-avatar-bg) no-repeat center/cover;
  border-radius: var(--tblr-border-radius);
  box-shadow: var(--tblr-avatar-shadow);
}
 
/*------------------------------------------------------------------
[ colors ]*/

.bg-amber {
  background-color: #ffc107 !important;
}
.bg-tahitiGold {
  background-color: #ed9007 !important;
}
.bg-charade {
  background-color: #21222d;
}
.bg-white-lilac {
  background-color: #f6f7fb;
}
.bg-sorbus {
  background-color: #fe7c04;
}

.bg-info-100 {
  background-color: #ebf3ff;
}

.bg-gray-100 {
  background-color: #E8F2F5;
}

.cl-gray-100 {
  color: #575f6e;
}

.cl-gray-200 {
  color: #cdced0;
}

.black-light {
  color: #0000008a;
}

.cl-gray-700 {
  color: #51565f;
}

.Cloud-White{
  background-color:#F8F9FB;
}

/* .Cloud-Whi-b{
  background-color:#F8F9FB;

} */

.cl-gray-900 {
  color: #454545;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

 .cl-tahitiGold,
.cl-tahitiGold .mdc-button__label {
  color: #ed9007;
}
.cl-white,
.cl-white .mdc-button__label {
  color: #fff;
}
.cl-sorbus {
  color: #fe7c04;
}
.cl-black {
  color: #000;
}
.cl-mine-shaft {
  color: #353434;
}
.cl-muted {
  color: #747272;
}
.cl-info {
  color: #3662fa;
}
.cl-alto {
  color: #d9d9d9;
}
.cl-gray {
  color: #8a8585;
}
.cl-shamrock {
  color: #42d8a7;
}

.cl-link {
  color: #2f6d95;
}


.auth-style {
  min-height: calc(100vh - 64px);
}

.auth-style .logo {
  font-size: 1.25rem;
  margin-right: 1rem;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}

.auth-style .logo img {
  width: 20rem;
}

.auth-style .mat-mdc-card {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.09);
  border: 1px solid #e2dede;
  border-radius: 4px;
} 

.auth-style button[type="submit"] {
  height: 40px;
  min-width: 166px;
  font-size: 16px;
  padding-top: 1px;
}

.border-1 {
  border: 1px solid #d2cece;
}

.border-bottom-1 {
  border-bottom: 1px solid #d2cece;
}

.border-color-sorbus {
  border-color: #fe7c04 !important;
}
.text-custom-blue {
  color: #3662FA;
  font-size: 0.875rem; 
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
  
}

.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-10 {
  font-size: 10px;
}

.img-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.min-h-100vh {
  min-height: 100vh;
}

/* === Spacing ====*/

.mb-24 {
  margin-bottom: 1.95rem;
}

@media (max-width: 480px) {
  .auth-style {
    background-color: #fff !important;
  }
  .auth-style .container {
    padding: 0;
  }
  .auth-style .mat-mdc-card {
    box-shadow: none;
    border: none;
  }
  .auth-style .mat-mdc-card .mat-mdc-card-content {
    padding: 0 15px;
  }
  .auth-style .logo {
    display: none;
  }
  .auth-style form {
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-top: 0 !important;
  }
}

.card-border-none {
  border: none;
}

.transition-transform-500 {
  transition: transform ease-in-out 500ms;
}

.transition-all-500 {
  transition: all ease-in-out 500ms;
}

.line {
  height: 1px;
}

.height-fit-content {
  height: fit-content;
}

.cursor-pointer {
  cursor: pointer;
}

.container-init-app {
  padding: 1.5rem 1.5rem;
}

app-select,
app-date,
app-phone,
app-autocomplete {
  position: relative;
}

.table__first {
  width: 100px;
  min-width: 100px;
}

.table__first__v1 {
  width: 80px;
  min-width: 80px;
}
 
/* ===  Map === */

mgl-map {
  height: 300px;
}

.btn-submit {
  width: 100%;
}

@media (min-width: 1000px) {
  .btn-submit {
    width: fit-content;
  }

  .container-init-app {
    padding: 2rem 6.25rem;
  }
}

.progess-success .mdc-linear-progress__bar-inner {
  border-color: rgba(25, 135, 84, 1) !important;
}

.no-user-select {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.cursor-grab {
  cursor: grab;
}
 
/* Prime-NG Styles */
.p-dropdown, .p-calendar, .p-inputtext , .p-dropdown, .p-inputnumber{
  width: 100%;
}

p-inputnumber {
  display: block;
}


.p-dialog-header {
  flex-direction: column-reverse;
}

.p-dialog-header-icons {
  width: 100%;
    text-align: right;
    justify-content: end;
    display: flex;
}
.text-orange{
  color:#F0692C ;
}

.text-detail {
font-size: 14px;
  font-weight: 500;
  line-height: 16.41px;
  background: #fff;
  color: #5F7081;
}

.text-base{
  font-family: 'Roboto', sans-serif;
  text-align: left;
  text-decoration-skip-ink: none;
  text-underline-position: from-font;
  padding: 0.25rem 0.5rem;
}

/* .custom-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  background: #212121;
  color: white;
} */

.custom-text {
  font-size: 20px;
  font-weight: 700;
  line-height: 23.44px;
  color: #212121; /* Cambié aquí el color del texto */
  background: #fff; /* Fondo blanco */
}



