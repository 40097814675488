.btn {
	--bs-btn-padding-x: 1.5rem;
	--bs-btn-padding-y: 0.5rem;
	--bs-btn-font-family: ;
	--bs-btn-font-size: 1rem;
	--bs-btn-font-weight: 500;
	--bs-btn-line-height: 1.5;
	--bs-btn-color: var(--bs-body-color);
	--bs-btn-bg: transparent;
	--bs-btn-border-width: var(--bs-border-width);
	--bs-btn-border-color: transparent;
	--bs-btn-border-radius: var(--bs-border-radius);
	--bs-btn-hover-border-color: transparent;
	--bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
	--bs-btn-disabled-opacity: 0.65;
	--bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
}

.btn-primary {
	--bs-btn-color: #fff;
	--bs-btn-bg: #19809e;
	--bs-btn-border-color: #2aa2c7;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #24a3ca;
	--bs-btn-hover-border-color: #2a9cbe;
	--bs-btn-focus-shadow-rgb: 248, 152, 62;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #1b97bd;
	--bs-btn-active-border-color: #35aace;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #19809e;
	--bs-btn-disabled-border-color: #2aa2c7;
}

.btn-outline-primary {
	--bs-btn-color: #19809e;
	--bs-btn-border-color: #19809e;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #19809e;
	--bs-btn-hover-border-color: #19809e;
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #19809e;
	--bs-btn-active-border-color: #19809e;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #19809e;
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: #19809e;
	--bs-gradient: none;
}

.btn-secondary {
	--bs-btn-color: #fff;
	--bs-btn-bg: #F0692C;
	--bs-btn-border-color: #fa884f;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #fe7c04;
	--bs-btn-hover-border-color: #fda450;
	--bs-btn-focus-shadow-rgb: 248, 152, 62;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #f17a3e;
	--bs-btn-active-border-color: #f8996a;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #F0692C;
	--bs-btn-disabled-border-color: #fa884f;
}


.btn-outline-secondary {
	--bs-btn-color: #F0692C;
	--bs-btn-border-color: #F0692C;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #F0692C;
	--bs-btn-hover-border-color: #F0692C;
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #F0692C;
	--bs-btn-active-border-color: #F0692C;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #F0692C;
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: #F0692C;
	--bs-gradient: none;
}

.btn-orange {
	--bs-btn-color: #fff;
	--bs-btn-bg: #fe7c04;
	--bs-btn-border-color: #fe7c04;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #ff8f26;
	--bs-btn-hover-border-color: #ff8f26;
	--bs-btn-focus-shadow-rgb: 248, 152, 62;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #ff932f;
	--bs-btn-active-border-color: #ff932f;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #fff;
	--bs-btn-disabled-bg: #fe7c04;
	--bs-btn-disabled-border-color: #fe7c04;
}

.btn-outline-orange {
	--bs-btn-color: #fe7c04;
	--bs-btn-border-color: #fe7c04;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #ff8e24;
	--bs-btn-hover-border-color: #ff8e24;
	--bs-btn-focus-shadow-rgb: 13, 110, 253;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #ff8e24;
	--bs-btn-active-border-color: #ff8e24;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #ff8e24;
	--bs-btn-disabled-bg: transparent;
	--bs-btn-disabled-border-color: #ff8e24;
	--bs-gradient: none;
}

.btn-dark {
	--bs-btn-color: #fff;
	--bs-btn-bg: black;
	--bs-btn-border-color: transparent;
	--bs-btn-hover-color: #fff;
	--bs-btn-hover-bg: #141414;
	--bs-btn-hover-border-color: transparent;
	--bs-btn-focus-shadow-rgb: 248, 152, 62;
	--bs-btn-active-color: #fff;
	--bs-btn-active-bg: #000000;
	--bs-btn-active-border-color: transparent;
	--bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
	--bs-btn-disabled-color: #dadada;
	--bs-btn-disabled-bg: #3a3a3a;
	--bs-btn-disabled-border-color: transparent;
}
